




























































































































































































import {Component, Vue} from 'vue-property-decorator';
import {
  namespace,
} from 'vuex-class';
import {RoutingHelper} from '@/helper/RoutingHelper';
import Tenant from '@/models/Tenant';
import User from '@/models/User';
import {validationMixin} from 'vuelidate';
import {required} from 'vuelidate/lib/validators';
import {mixins} from 'vue-class-component';
import ErrorMessageHandlerMixin from '@/helper/ErrorMessageHandler.mixin';
import {authStoreActions} from '@/stores/auth.store';
import {tenantStoreActions, tenantStoreMutations} from '@/stores/tenant.store';
import {LoginBody, ResetPWBody} from '@/interfaces/Auth';

const AuthStore = namespace('auth');
const TenantStore = namespace('tenant');
const UserStore = namespace('user');

/**
 * This view provides a login for each user role.
 */
@Component({
  mixins: [validationMixin],
  validations: {
    loginData: {
      username: {required},
      password: {required},
    },
    forgotPasswordData: {
      email: {required},
    },
  },
})
export default class LoginView extends mixins(ErrorMessageHandlerMixin) {

  public applicationName: string = process.env.VUE_APP_TITLE!;

  @AuthStore.Action(authStoreActions.LOGIN_ACTION)
  private loginAction!: (value: LoginBody) => Promise<User>;
  @AuthStore.Action(authStoreActions.RESET_PASSWORD_ACTION)
  private resetPasswordAction!: (login: ResetPWBody) => Promise<void>;
  @TenantStore.Mutation(tenantStoreMutations.ACTIVE_TENANT)
  private activeTenant!: (tenant: Tenant) => void;
  @TenantStore.Action(tenantStoreActions.LOAD_TENANT_ACTION)
  private loadTenantAction!: (tenantId: string) => Promise<Tenant>;
  @AuthStore.Action(authStoreActions.TEST_ENVIRONMENT)
  private testEnvironment!: () => Promise<User>;

  /**
   *  Login data model
   */
  public loginData: LoginBody = {
    username: undefined,
    password: undefined,
  };
  public loginFormWasSubmitted: boolean = false;
  private showTestDialog: boolean = false;
  /**
   *  Forgot password model
   */
  public forgotPasswordData: ResetPWBody = {
    email: undefined,
  };

  /**
   * Support form variable
   */
  public loginForgotFormWasSubmitted: boolean = false;

  /**
   * Support form variable
   */
  public onForgotPasswordDialogFlag: boolean = false;

  /**
   *  Close handler for forgot password dialog handler
   */
  public async onForgotPasswordDialogClose() {
    this.onForgotPasswordDialogFlag = false;
    Vue.nextTick(() => {
      Object.keys(this.forgotPasswordData).forEach((key) => this.forgotPasswordData[key] = undefined);
      this.$v.forgotPasswordData!.$reset();
      this.loginForgotFormWasSubmitted = false;
    });
  }

  public async onTestEnvironmentClick() {
    const loginUser = await this.testEnvironment();
    loginUser.tenant = await this.loadTenantAction(loginUser.tenantId!);
    await this.RouteAfterLogin(loginUser);
  }

  /**
   * Performs the reset password request
   */
  public async onForgotPassword() {
    this.$v.forgotPasswordData!.$touch();
    this.loginForgotFormWasSubmitted = true;

    try {
      if (!this.$v.forgotPasswordData!.$invalid && this.loginForgotFormWasSubmitted) {
        await this.resetPasswordAction(this.forgotPasswordData!);
        this.$notifySuccessSimplified('LOGIN.NOTIFICATIONS.RESET_PASSWORD.SUCCESS');
        await this.onForgotPasswordDialogClose();
      }
    } catch (e) {
      if (this.$te(`LOGIN.NOTIFICATIONS.RESET_PASSWORD.${e.status}.TEXT`)) {
        switch (e.status) {
          case 429:
            e.data.resendAllowedAt = this.$options.filters!.toDateTime(e.data.resendAllowedAt); // format date
            this.$notifyErrorSimplified(`LOGIN.NOTIFICATIONS.RESET_PASSWORD.${e.status}`, e.data, 10000);
            break;
          case 410:
            this.$notifyErrorSimplified(`LOGIN.NOTIFICATIONS.RESET_PASSWORD.410`);
            break;
          default:
            this.$notifyWarningSimplified(`LOGIN.NOTIFICATIONS.RESET_PASSWORD.${e.status}`);
            break;
        }
      } else if (e.status === 410) {
        this.$notifyErrorSimplified(`LOGIN.NOTIFICATIONS.RESET_PASSWORD.410`);
      } else {
        this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.GENERAL_ERROR');
      }
    }
  }

  /**
   * Performs the login
   */
  public async onLogin() {
    this.$v.loginData!.$touch();
    this.loginFormWasSubmitted = true;

    try {
      if (!this.$v.loginData!.$invalid && this.loginFormWasSubmitted) {
        const loginUser = await this.loginAction(this.loginData);
        await this.RouteAfterLogin(loginUser);
      }
    } catch (e) {
      if (this.$te(`LOGIN.NOTIFICATIONS.${e.status}.TEXT`)) {
        this.$notifyError(
            this.$t(`LOGIN.NOTIFICATIONS.GENERAL_TITLE`).toString(),
            this.$t(`LOGIN.NOTIFICATIONS.${e.status}.TEXT`).toString(),
        );
      } else {
        this.$notifyErrorSimplified('LOGIN.NOTIFICATIONS.GENERAL_ERROR');
      }
    }
  }

  public async RouteAfterLogin(loginUser: User) {
    let optionalParams;

    // consider tenant id to route
    if (!this.$userRoleHandler.isSuperAdmin()) {
      this.activeTenant(loginUser.tenant!);
      optionalParams = {
        tenantId: (loginUser.tenant as Tenant).id!,
      };
    }
    await this.$router.push({
      name: RoutingHelper.getDefaultRoute(),
      params: optionalParams,
    });
  }
}
